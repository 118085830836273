// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import {Modal} from "bootstrap";

var jQuery = require('jquery');

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

Rails.start();

// Standard playback event handlers.
$(document).on('ngn1.assetsLoaded', function (event, playlistType, assetsToPlay) {
    console.log('Assets Loaded Received with ' + assetsToPlay.length + ' tracks.');
    return true;
})

$(document).on('ngn1.sessionValidated', function (event, ngn1) {
    console.log('Session Validated Received');
})

$(document).on('ngn1.invalidSkip', function (event, lastSkipAt) {
    var skipsModal = new Modal(document.getElementById('#skips-modal'));
    skipsModal.show();
})